import React, { Component } from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import RegistrationWelcomePage from './WelcomePage/RegistrationWelcomePage';
import BusinessSearch from './BusinessSearch/BusinessSearch';
import LandingPage from './LandingPage/LandingPage';
import EnterVerificationCode from './EnterVerificationCode/EnterVerificationCode';
import PinNotReceived from './PinNotReceived/PinNotReceived';
import VerifyEmail from './VerifyEmail/VerifyEmail';
import ServiceData from './ServicePacks/ServiceData';
import ServicePacks from './ServicePacks/ServicePacks';
import OrderSummary from './OrderSummary/OrderSummary';
import CreateAccount from './CreateAccount/CreateAccount';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import RegistrationSuccess from './RegistrationSuccess/RegistrationSuccess';
import RegistrationFail from './RegistrationFail/RegistrationFail';
import { validateEmail, validatePhoneNo } from '../utils/Validation';
import { postRequest, apiParse } from '../utils/Common';

import TagManager from 'react-gtm-module';

import config from '../config';
const env = process.env.NODE_ENV || 'development';

// console.log('process.env.NODE_ENV -', process.env.NODE_ENV);
// console.log('process.env.REACT_APP_DEV_VERIFYURL - ', process.env.REACT_APP_DEV_VERIFYURL);
// console.log('process.env.REACT_APP_DEV_BIZIQURL - ', process.env.REACT_APP_DEV_BIZIQURL);
// console.log('process.env.REACT_APP_DEV_BIZIQPORT - ', process.env.REACT_APP_DEV_BIZIQPORT);

// console.log('process.env.REACT_APP_PROD_URL - ', process.env.REACT_APP_PROD_URL);
// console.log('process.env.REACT_APP_PROD_VERIFYURL - ', process.env.REACT_APP_PROD_VERIFYURL);
// console.log('process.env.REACT_APP_PROD_BIZIQPORT - ', process.env.REACT_APP_PROD_BIZIQPORT);

// console.log('config[env].PORT:', config[env].PORT);
// console.log('config[env].URL:', config[env].URL);
// console.log('config[env].BIZIQURL:', config[env].BIZIQURL);
// console.log('config[env].VERIFYURL:', config[env].VERIFYURL);
// console.log('config[env].BIZIQPORT:', config[env].BIZIQPORT);

class App extends Component {
	constructor(props) {
		super(props);

		this.state = this.getInitialState();

		this.handleContinue = this.handleContinue.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onBlurEvent = this.onBlurEvent.bind(this);
		this.onEmailBlur = this.onEmailBlur.bind(this);
		this.onPhoneNoBlur = this.onPhoneNoBlur.bind(this);
		this.updateState = this.updateState.bind(this);
		this.onCanSubmit = this.onCanSubmit.bind(this);
		this.onHandleSubmit = this.onHandleSubmit.bind(this);
		this.setError = this.setError.bind(this);
		this.setPhoneNumber = this.setPhoneNumber.bind(this);
		this.setAddress = this.setAddress.bind(this);
		// this.setRegisteredAddress = this.setRegisteredAddress.bind(this);
		this.resetAddrState = this.resetAddrState.bind(this);
		this.resetRegisteredAddrState = this.resetRegisteredAddrState.bind(this);
		this.setPinVerified = this.setPinVerified.bind(this);
		this.setEmailVerified = this.setEmailVerified.bind(this);
		this.getButtonText = this.getButtonText.bind(this);
	}

	// PMD 24/01/24 Lifecycle not supported in React 18
	// componentWillUpdate(nextProps, nextState) {
	UNSAFE_componentWillUpdate(nextProps, nextState) {

		let { eMail, showFail } = nextState;

		if (eMail !== undefined && eMail !== this.state.eMail) {
			if (eMail.length === 0) {
				this.updateState(null, 'eMail', eMail);
			} else {
				//validate the value we have
				validateEmail(eMail)
					.then(value => this.updateState(null, 'eMail', eMail))
					.catch(error => this.updateState(error, 'eMail', eMail))
			}
		}

		if (showFail !== undefined && showFail !== this.state.showFail) {
			if (this.state.showFail) {
				this.resetState();
			}
		}

		/*
		if (this.state.closeWindow || nextState.closeWindow) {
			window.open(window.location, '_self').close();   
			return false; 	
		}
		*/
		if (this.state.closeWindow || nextState.closeWindow) {
			window.location.replace('https://spotlite.services/registration-complete/')
		}
	}

	getInitialState = () => {
		const initialState = {
			renderWelcomePage: true,
			currency: "GBP",
			locale: "en-GB",
			sessionId: '',
			surname: '',
			middleName: '',
			forename: '',
			confirmEmail: '',
			eMail: '',
			selectedAddress: {},
			registeredAddress: {},
			addressSelected: false,
			phoneNumber: '',
			pinGenerated: false,
			pinVerified: false,
			pinNotReceived: false,
			resendVerificationType: null,
			// emailVerified: false,
			emailVerified: true,
			error: null,
			errorList: null,
			errors: {
				surname: true,
				forename: true,
				confirmEmail: true,
				eMail: true,
				phoneNumber: true,
				// password: true,
				// passwordConfirm: true
			},
			password: 'unusedPassw0rd01!',
			workInProgress: false,
			businessRef: null,
			businessName: null,
			departmentName: null,
			businessConfirmed: false,
			catalogueSelected: null,
			catalogueName: null,
			numberOfCredits: null,
			promoSelected: null,
			promoCode: null,
			packSelected: '',
			subscriptions: null,
			services: null,
			SpotliteClientCatalogue: null,
			servicePacksState: null,
			subscriptionSelected: null,
			addonSelected: [],
			totalCredits: '',
			totalNet: '',
			totalTax: '',
			orderTotal: '',
			totalExclVat: '',
			orderDescription: '',
			showSummary: false,
			createNewAccount: false,
			showPayment: false,
			showSuccess: false,
			showFail: false,
			payByInvoice: false,
			invoiceNumber: null,
			closeWindow: false,
			subscriptionsData: [],
			servicesData: [],
			addonData: [],
			totalOrderExclTax: 0,
			totalOrderInclTax: 0,
			spotliteStartDate: "",
			spotliteEndDate: "",
			spotliteDueDate: "",
			billingFrequency: "",
			currencyCode: "",
			isSubscriptionVisible: false,
			isAddressEdited: false,
			dbs: false,
			manualBypass1: false,
			manualBypass2: false,
			manualBypass3: false,
		};

		return initialState;
	};

	resetState = () => {
		// console.log('resetState fired !!!!');
		this.setState(this.getInitialState());
	};

	onChange(event) {
		let targetName = event.target.name;
		let targetValue = event.target.value;

		// Clear previous error message if user enters a character in field
		let error = this.state.error ? null : this.state.error;

		this.updateState(error, targetName, targetValue);
	}

	onBlurEvent(event) {

		let targetName = event.target.name;
		let targetValue = event.target.value;
		let error;

		if (targetName === 'surname' || targetName === 'forename') {
			if (targetValue.length === 0) {
				error = 'Your name is required';
			} else {
				error = null;
			}
		}

		this.updateState(error, targetName, targetValue);
	}

	updateState(error, targetName, targetValue) {
		this.setState({
			...this.state,
			selectedAddress: {
				...this.state.selectedAddress
			},
			registeredAddress: {
				...this.state.registeredAddress
			},
			errorList: {
				...this.state.errorList,
				[targetName]: error
			},
			error: error,
			[targetName]: targetValue,
			errors: {
				...this.state.errors,
				[targetName]: error ? true : false
			},
		});
	}

	setError(error) {
		this.setState({
			error
		});
	}

	setAddress(address) {

		this.setState({
			...this.state,
			addressSelected: true,
			registeredAddress: {
				...this.state.registeredAddress,
				contactAddr1: address[0].addressLine1,
				contactAddr2: address[1].addressLine2,
				contactAddr3: address[2].addressLine3,
				contactCity: address[3].locality,
				contactState: address[4].province,
				contactPostCode: address[5].postalCode,
				contactCountry: address[6].country
			},
			selectedAddress: {
				...this.state.selectedAddress,
				contactAddr1: address[0].addressLine1,
				contactAddr2: address[1].addressLine2,
				contactAddr3: address[2].addressLine3,
				contactCity: address[3].locality,
				contactState: address[4].province,
				contactPostCode: address[5].postalCode,
				contactCountry: address[6].country
			},
			errors: {
				...this.state.errors
			},
		});
	}

	// PMD 26/01/24 Value set in single function 
	// setRegisteredAddress(address) {

	// 	this.setState({
	// 		...this.state,
	// 		registeredAddress: {
	// 			...this.state.registeredAddress,
	// 			addr1: address[0].addressLine1,
	// 			addr2: address[1].addressLine2,
	// 			addr3: address[2].addressLine3,
	// 			city: address[3].locality,
	// 			state: address[4].province,
	// 			postCode: address[5].postalCode,
	// 			country: address[6].country
	// 		},
	// 		selectedAddress: {
	// 			...this.state.selectedAddress
	// 		},
	// 		errors: {
	// 			...this.state.errors
	// 		},
	// 	});
	// }

	resetAddrState() {
		this.setState({
			addressSelected: false,
			registeredAddress: {
				...this.state.registeredAddress
			},
			selectedAddress: {
				...this.state.selectedAddress,
				contactAddr1: '',
				contactAddr2: '',
				contactAddr3: '',
				contactCity: '',
				contactState: '',
				contactPostCode: '',
				contactCountry: ''
			},
			errors: {
				...this.state.errors
			},
		})
	}

	resetRegisteredAddrState() {
		this.setState({
			addressSelected: false,
			registeredAddress: {
				...this.state.registeredAddress,
				addr1: '',
				addr2: '',
				addr3: '',
				city: '',
				state: '',
				postCode: '',
				country: ''
			},
			selectedAddress: {
				...this.state.selectedAddress
			},
			errors: {
				...this.state.errors
			},
		})
	}
	setPhoneNumber(phoneNumber) {
		// Clear previous error message if user enters a character in field
		let error = this.state.error ? null : this.state.error;

		this.updateState(error, 'phoneNumber', phoneNumber)
	}

	onCanSubmit() {
		let { error, errors, addressSelected } = this.state;

		return error || Object.values(errors).indexOf(true) > -1 || !addressSelected
			? false
			: true;
	}

	onHandleSubmit(event) {
		const { sessionId, businessName, forename, surname, eMail, phoneNumber, resendVerificationType } = this.state;

		// Don't submit the form traditionally
		event.preventDefault();
		let URI;
		let options = {};
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		if (this.onCanSubmit()) {
			if (resendVerificationType === 'SMS') {
				// URI = `${config[env].URL}${config[env].PORT}/home/newpin`;
				// PMD 02/09/21 Remove PORT and add /home for use by API Gateway
				// URI = `${config[env].VERIFYURL}/home/newpin`;
				URI = `${config[env].VERIFYURL}/newpin`;
				options = {
					sessionId: sessionId,
					contactPhone: phoneNumber
				};
				// console.log(URI);
			} else {
				// URI = `${config[env].URL}${config[env].PORT}/home/contact`;

				// PMD 02/09/21 Remove PORT and add /home for use by API Gateway
				// URI = `${config[env].VERIFYURL}/home/contact`;
				URI = `${config[env].VERIFYURL}/contact`;
				options = {
					sessionId: sessionId,
					businessName: businessName,
					forename: forename,
					surname: surname,
					contactEmail: eMail,
					contactPhone: phoneNumber,
					resendVerificationType: resendVerificationType
				};
				// console.log(URI, options);
			}
			this.setState({ workInProgress: true });

			// const credentials = 'include';
			const credentials = 'omit';
			return postRequest(URI, options, credentials, headers)
				.then(apiParse)
				.then(response => {
					this.setState({
						sessionId: response.sessionId ? response.sessionId : sessionId,
						workInProgress: false,
						pinGenerated: true,
						pinNotReceived: false,
						manualBypass1: false,
						manualBypass2: true,
						manualBypass3: false,
					})
					return response;
				})
				.catch(error => {

					// console.log("Error", error);

					this.setState({
						workInProgress: false,
						pinNotReceived: false,
						error: error.message || error,
						errorList: {
							registerError: error.message || error
						}
					})
					return error;
				})
				.then(result => {
					if (resendVerificationType !== 'SMS') {
						const renderResponse = (responseObj) => {
							return Object.keys(responseObj)
								.map((key) => {
									if (responseObj[key]) {
										return `${key}: ${responseObj[key]}<br />`;
									}
									return '';
								})
								.join('');
						};
						const messageBody = `<h3>FAO: Customer Services,</h3>
						<p>
						Details below of a new customer Enquiry.
						<br/> <br />
						Name: ${forename} ${surname}
						<br /><br />
						Email Address: ${eMail}
						<br />
						Telephone Number: ${phoneNumber}
						<br /><br />
						${renderResponse(result)}
						</p>`;

						// PMD 02/09/21 Remove PORT and add /home for use by API Gateway
						// URI = `${config[env].URL}${config[env].PORT}/home/enquiry`;
						URI = `${config[env].URL}/enquiry`;

						options = {
							businessName: businessName,
							messageBody: messageBody
						};

						// Send internal email to customer services, do not check for errors
						return postRequest(URI, options, credentials, headers)
							.then(apiParse)
							.then(result => result)
							.catch(err => err)
					}
					return;
				})
		}
	}

	getButtonText() {
		return this.state.workInProgress ? "Please Wait..." : "Send Text";
	}

	calculateOrderTotal = (total) => {
		this.setState({
			totalExclVat: total
		});
	};

	getSpotliteClientCatalogue = () => {
		// PMD 02/09/21 Remove PORT and add /home for use by API Gateway
		// const URI = `${config[env].URL}${config[env].PORT}/home/get-config`;
		const URI = `${config[env].URL}/get-config`;
		const options = {
			appConfig: 'RCATALOG',
			servicePackID: 'DEFAULT'
		};

		// console.log(URI)
		const credentials = 'omit';
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		return postRequest(URI, options, credentials, headers)
			.then(apiParse)
			.then(catalogueData => {
				if (catalogueData instanceof Error) {
					throw catalogueData;
				}
				this.setState({
					SpotliteClientCatalogue: catalogueData
				});
			})
			.catch(error => this.setState({ error: error.message || error }));
	}

	handleServicePackSubmit = (purchaseOptions, servicePacksState) => {

		const { businessName, forename, surname, eMail, phoneNumber } = this.state;
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		//console.log(purchaseOptions, servicePacksState);

		this.setState({
			...purchaseOptions,
			servicePacksState: {
				...servicePacksState
			},
			showSummary: true
		});

		const messageBody = `<h3>FAO: Customer Services,</h3>
		<p>
		Details below of a new customer Enquiry, [Get Quote] Button clicked.
		<br/> <br />
		Name: ${forename} ${surname}
		<br /><br />
		Email Address: ${eMail}
		<br />
		Telephone Number: ${phoneNumber}
		</p>`;

		// PMD 02/09/21 Remove PORT and add /home for use by API Gateway
		// const URI = `${config[env].URL}${config[env].PORT}/home/enquiry`;
		const URI = `${config[env].URL}/enquiry`;
		const credentials = 'omit';
		const options = {
			businessName: businessName,
			forename: forename,
			surname: surname,
			contactEmail: eMail,
			contactPhone: phoneNumber,
			messageBody: messageBody
		};

		// Send internal email to customer services, do not check for errors
		return postRequest(URI, options, credentials, headers)
			.then(apiParse)
			.then(result => result)
			.catch(err => err)
	};

	handleSummaryConfirm = (promoSelected, subscriptions, services, totalCredits, orderTotal, totalNet, totalTax, shortDesc) => {
		this.setState({
			//	showPayment: true,
			showSummary: false,
			createNewAccount: true,
			promoSelected: promoSelected,
			subscriptions: subscriptions,
			services: services,
			totalCredits: totalCredits,
			orderTotal: orderTotal,
			totalNet: totalNet,
			totalTax: totalTax,
			orderDescription: shortDesc,
		});
	};

	handleAccountCreation = (values) => {
		const { error, invoiceNumber } = values;

		this.setState({
			error: error ? error : null,
			showPayment: error ? false : true,
			showFail: error ? true : false,
			invoiceNumber: invoiceNumber
		})
	}

	handleSummaryCancel = (subscriptionsData) => {
		this.setState({
			subscriptionsData:subscriptionsData,
			createNewAccount: false,
			showSummary: false,
			showFail: false
		});
	};
	/* PROMO REFERRAL - Promotion Code Referrer update commented out for now
		handleReferral = () => {
			const URI = `${config[env].URL}${config[env].PORT}/home/update-referrer`;
	
			const options = {
				businessName: this.state.businessName,
				referrerCompanyNumber: this.state.promoSelected.ReferrerCompanyNumber,
				referrerDepartmentName: this.state.promoSelected.ReferrerDepartmentName,
				referrerSecure_Id: this.state.promoSelected.ReferrerSecure_Id,
				item: this.state.promoSelected.Type,
				referralCredits: this.state.promoSelected.ReferralCredits,
				additionalInfo: `Referral credits from new customer ${this.state.eMail}, ${this.state.businessName}`
			};
			const credentials = 'include';
			const headers = {'appkey': process.env.REACT_APP_APP_KEY};
	
			postRequest(URI, options, credentials, headers)
				.then(apiParse)
				.then(response => response)
				.catch(error => this.setState({error: error.message || error, showSuccess: true, showFail: false}));
		}
	*/

	handlePaymentSuccess = (paymentIntent) => {
		// Update businessAccount account on hold status to false and any referral

		// PMD 02/09/21 Remove PORT and add /home for use by API Gateway
		// const URI = `${config[env].URL}${config[env].PORT}/home/set-account-status`;
		const URI = `${config[env].URL}/set-account-status`;

		const options = {
			businessRef: this.state.businessRef,
			departmentName: this.state.departmentName,
			businessName: this.state.businessName,
			forename: this.state.forename,
			registeredAddress: this.state.registeredAddress,
			contactEmail: this.state.eMail,
			phoneNumber: this.state.phoneNumber,
			invoiceNumber: this.state.invoiceNumber,
			id: paymentIntent.id,
			purchasedVia: 'Registration Portal'
		};

		const credentials = 'omit';
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		postRequest(URI, options, credentials, headers)
			.then(apiParse)
			.then((response) => {
				this.setState({ showSuccess: true, showFail: false });

				/* PROMO REFERRAL - Promotion Code Referrer update commented out for now
								if (this.state.promoSelected && this.state.promoSelected.ReferrerCompanyNumber) {
									this.handleReferral();
								}
				*/
			})
			.catch(error => this.setState({ error: error.message || error, showFail: true }));
	};

	handleNotReceivedButton = type => {
		this.setState({
			pinGenerated: false,
			pinNotReceived: true,
			resendVerificationType: type,
			manualBypass1: false,
			manualBypass2: false,
			manualBypass3: false,
		});
	};

	setPinVerified(verified, type) {
		this.setState({
			pinVerified: verified,
			resendVerificationType: type,
			manualBypass1: false,
			manualBypass2: false,
			manualBypass3: true,
		});
	}

	setEmailVerified(value, type) {
		this.setState({
			emailVerified: value,
			resendVerificationType: type
		});
	}

	onEmailBlur(event) {
		const emailAddress = event.target.value;
		const targetName = event.target.name;

		const { eMail, confirmEmail } = this.state;
		/*
		console.log('************');
		console.log('targetName:', targetName);
		console.log('emailAddress:', emailAddress);
		console.log('eMail:', eMail);
		console.log('confirmEmail:', confirmEmail);
		console.log('---');
		console.log('error:', this.state.error);
		console.log('errorList:', this.state.errorList);
		console.log('errors:', this.state.errors);
		console.log('____________');
		*/
		if (emailAddress.length === 0) {
			// console.log('1)');
			this.updateState('An email address is required', targetName, emailAddress);
		} else if (targetName === 'eMail') {
			const URI = `${config[env].URL}/email`;
			const options = {
				email: emailAddress,
			};
			const credentials = 'omit';
			const headers = { 'appkey': process.env.REACT_APP_APP_KEY };
			return validateEmail(emailAddress)
				.then(() => {
					return postRequest(URI, options, credentials, headers)
						.then(apiParse)
						.then(() => {
							// console.log('2)');
							if (emailAddress === confirmEmail) {
								this.updateState(null, targetName, emailAddress);
								this.updateState(null, 'confirmEmail', confirmEmail);
							} else if (confirmEmail && emailAddress !== confirmEmail) {
								this.updateState('The email addresses entered do not match!', 'confirmEmail', confirmEmail);
							}
							return;
						})
						.catch(err => { throw err })
				})
				.catch(err => this.updateState(err.message || err, targetName, emailAddress))
		} else if (targetName === 'confirmEmail' && emailAddress !== eMail) {
			// console.log('3)');
			this.updateState(null, 'eMail', eMail);
			this.updateState('The email addresses entered do not match!', targetName, emailAddress);
		} else if (targetName === 'confirmEmail' && emailAddress === eMail) {
			// console.log('4)');
			this.updateState(null, 'eMail', eMail);
			this.updateState(null, 'confirmEmail', confirmEmail);
		}
	}

	onPhoneNoBlur(event) {
		const phoneNumber = event.target.value || '';
		const targetName = 'phoneNumber';

		const URI = `${config[env].URL}/phone`;
		const options = {
			phone: phoneNumber
		};
		const credentials = 'omit';
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		if (phoneNumber.length === 0) {
			this.updateState('Telephone number is required', targetName, phoneNumber);
		} else {
			validatePhoneNo(phoneNumber)
				.then(() => {
					return postRequest(URI, options, credentials, headers)
						.then(apiParse)
						.then(data => this.updateState(null, targetName, phoneNumber))
						.catch(err => { throw err })
				})
				.catch(err => this.updateState(err.message || err, targetName, phoneNumber))
		}
	}

	goDirect = () => {
		this.setState({
			manualBypass1: true,
			addressSelected: true
		});
	}

	updateBusinessRef = (businessRef, businessName, department, isEdited) => {

		this.setState({
			isAddressEdited: isEdited,
			businessRef: businessRef,
			businessName: businessName,
			departmentName: department
		});
	};

	handleContinue() {
		this.setState({ renderWelcomePage: false })
	}

	handlePayByInvoiceButton = (id) => {
		// Leave account on hold, send email for invoice and handle referral

		const URI = `${config[env].URL}/pay-by-invoice`;

		const options = {
			businessName: this.state.businessName,
			companyNumber: this.state.businessRef,
			departmentName: this.state.departmentName,
			forename: this.state.forename,
			surname: this.state.surname,
			contactEmail: this.state.eMail,
			contactPhone: this.state.phoneNumber,
			registeredAddress: this.state.registeredAddress,
			billingAddress: this.state.selectedAddress,
			id
		};

		const credentials = 'omit';
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		postRequest(URI, options, credentials, headers)
			.then(apiParse)
			.then((response) => {
				this.setState({
					showSuccess: true,
					showFail: false,
					payByInvoice: true
				});

				/* PROMO REFERRAL - Promotion Code Referrer update commented out for now
							  if (this.state.promoSelected && this.state.promoSelected.ReferrerCompanyNumber) {
								  this.handleReferral();
							  }
				*/
			})
			.catch(error => this.setState({ error: error.message || error, showFail: true }));
	};

	renderWelcomePage() {

		// console.log('renderWelcomePage ***');
		if (this.state.renderWelcomePage) {

			// Google Tag Manager data layer
			const tagManagerArgs = {
				dataLayer: {
					event: 'pageview',
					page: 'welcome'
				},
			};

			TagManager.dataLayer(tagManagerArgs);
			// console.log('renderWelcomePage - RegistrationWelcomePage ***');

			return (
				<RegistrationWelcomePage
					handleContinue={this.handleContinue}
				/>
			)
		}
	}

	renderBusinessSearch() {
		const { businessRef, renderWelcomePage, addressSelected } = this.state;
		const { contactAddr1, contactAddr2, contactAddr3, contactCity, contactPostCode, contactState } = this.state.selectedAddress;

		// console.log('renderBusinessSearch ');
		if (!businessRef && !renderWelcomePage) {

			// Google Tag Manager data layer
			const tagManagerArgs = {
				dataLayer: {
					event: 'pageview',
					page: 'search'
				}
			};

			TagManager.dataLayer(tagManagerArgs);
			// console.log('renderBusinessSearch - BusinessSearch ***');

			return (
				<BusinessSearch
					addressSelected={addressSelected}
					contactAddr1={contactAddr1}
					contactAddr2={contactAddr2}
					contactAddr3={contactAddr3}
					contactCity={contactCity}
					contactState={contactState}
					contactPostCode={contactPostCode}
					updateBusinessRef={this.updateBusinessRef}
					resetAddrState={this.resetAddrState}
					resetRegisteredAddrState={this.resetRegisteredAddrState}
					goDirect={this.goDirect}
					// setRegisteredAddress={this.setRegisteredAddress}
					setAddress={this.setAddress}
				/>
			)
		}
	}

	renderRegistrationForm() {
		const {
			surname, middleName, forename, confirmEmail, eMail, /* password,
			passwordConfirm, */ phoneNumber, pinGenerated, error, errors,
			errorList, workInProgress, businessRef, pinNotReceived, manualBypass1
		} = this.state;
		/*
			console.log('pinGenerated:', pinGenerated);
			console.log('businessRef:', businessRef);
			console.log('pinNotReceived:', pinNotReceived);
		*/
		if (!pinGenerated && businessRef && !pinNotReceived || manualBypass1) {
			// Google Tag Manager data layer
			const tagManagerArgs = {
				dataLayer: {
					event: 'pageview',
					page: 'registration_form'
				}
			};

			TagManager.dataLayer(tagManagerArgs);

			return (
				<LandingPage
					surname={surname}
					middleName={middleName}
					forename={forename}
					confirmEmail={confirmEmail}
					eMail={eMail}
					// password={password}
					// passwordConfirm={passwordConfirm}
					phoneNumber={phoneNumber}
					error={error}
					errors={errors}
					errorList={errorList}
					workInProgrerss={workInProgress}
					setPhoneNumber={this.setPhoneNumber}
					onEmailBlur={this.onEmailBlur}
					onPhoneNoBlur={this.onPhoneNoBlur}
					onChange={this.onChange}
					onBlurEvent={this.onBlurEvent}
					onCanSubmit={this.onCanSubmit}
					onHandleSubmit={this.onHandleSubmit}
					handleCancelButton={this.resetState}
					updateErrorState={this.updateState}
					getButtonText={this.getButtonText}
				/>
			)
		}
	}

	renderPinNotReceived = () => {
		const { pinNotReceived, eMail, phoneNumber, error, errors, resendVerificationType } = this.state;

		if (pinNotReceived) {
			// Google Tag Manager data layer
			const tagManagerArgs = {
				dataLayer: {
					event: 'pageview',
					page: 'pin_not_received'
				}
			};

			TagManager.dataLayer(tagManagerArgs);

			return (
				<PinNotReceived
					eMail={eMail}
					phoneNumber={phoneNumber}
					error={error}
					errors={errors}
					resendVerificationType={resendVerificationType}
					setPhoneNumber={this.setPhoneNumber}
					onEmailBlur={this.onEmailBlur}
					onPhoneNoBlur={this.onPhoneNoBlur}
					onChange={this.onChange}
					onCanSubmit={this.onCanSubmit}
					onHandleSubmit={this.onHandleSubmit}
				/>
			)
		}

		return null;
	};

	renderVerificationCode() {
		const { phoneNumber, pinGenerated, pinVerified, pinNotReceived, businessRef, sessionId, manualBypass2 } = this.state;

		if (pinGenerated && !pinVerified && businessRef && !pinNotReceived || manualBypass2) {
			// Google Tag Manager data layer
			const tagManagerArgs = {
				dataLayer: {
					event: 'pageview',
					page: 'verification_code'
				}
			};

			TagManager.dataLayer(tagManagerArgs);

			return (
				<EnterVerificationCode
					sessionId={sessionId}
					phoneNumber={phoneNumber}
					setPinVerified={this.setPinVerified}
					handleNotReceivedButton={this.handleNotReceivedButton}
				/>
			)
		}
	}

	/*
	  renderVerifyEmail() {
		  const {sessionId, pinVerified, emailVerified, pinNotReceived, resendVerificationType} = this.state;
  
		  if (pinVerified && !emailVerified && !pinNotReceived) {
			  // Google Tag Manager data layer
			  const tagManagerArgs = {
				  dataLayer: {
					  event: 'pageview',
					  page: 'verify_email'
				  }
			  };
  
			  TagManager.dataLayer(tagManagerArgs);
  
			  return(
				  <VerifyEmail
					  sessionId={sessionId}
					  resendVerificationType={resendVerificationType}
					  setEmailVerified={this.setEmailVerified}
					  handleNotReceivedButton={this.handleNotReceivedButton}
				  />
			  )
		  }	  
	  }
	  */

	renderServicePacks() {
		const { forename, eMail, SpotliteClientCatalogue, showSummary, showSuccess, showFail, currency, locale, servicePacksState, totalExclVat } = this.state;

		if (this.state.pinGenerated &&
			this.state.pinVerified &&
			this.state.businessRef &&
			this.state.emailVerified &&
			!showSummary &&
			!showSuccess &&
			!showFail &&
			!this.state.createNewAccount
			|| this.state.manualBypass3	
		) {

			// Google Tag Manager data layer
			const tagManagerArgs = {
				dataLayer: {
					event: 'pageview',
					page: 'service_packs'
				}
			};

			TagManager.dataLayer(tagManagerArgs);

			//pass isSubscriptionVisible into the props

			return (
				<ServiceData
					forename={forename}
					eMail={eMail}
					SpotliteClientCatalogue={SpotliteClientCatalogue}
					getSpotliteClientCatalogue={this.getSpotliteClientCatalogue}
					totalExclVat={totalExclVat}
					calculateOrderTotal={this.calculateOrderTotal}
					handleServicePackSubmit={this.handleServicePackSubmit}
					currency={currency}
					locale={locale}
					subscriptionsData={this.state.subscriptionsData}
					servicePacksState={servicePacksState}
				/>
			);

			/*
			return (
				<ServicePacks
					forename={forename}
					eMail={eMail}
					SpotliteClientCatalogue={SpotliteClientCatalogue}
					getSpotliteClientCatalogue={this.getSpotliteClientCatalogue}
					totalExclVat={totalExclVat}
					calculateOrderTotal={this.calculateOrderTotal}
					handleServicePackSubmit={this.handleServicePackSubmit}
					currency={currency}
					locale={locale}
					servicePacksState={servicePacksState}
				/>
			);
			*/
		}
	}

	renderSummary = () => {
		if (this.state.showSummary && !this.state.showSuccess && !this.state.showFail && !this.state.showPayment && !this.state.createNewAccount) {
			
			const summary = {
				businessRef: this.state.businessRef,
				businessName: this.state.businessName,
				contactEmail: this.state.eMail,
				forename: this.state.forename,
				middleName: this.state.middleName,
				surname: this.state.surname,
				// password: this.state.password,
				contactPhone: this.state.phoneNumber,
				contactAddr1: this.state.selectedAddress.contactAddr1,
				contactAddr2: this.state.selectedAddress.contactAddr2,
				contactAddr3: this.state.selectedAddress.contactAddr3,
				contactCity: this.state.selectedAddress.contactCity,
				contactCountry: this.state.selectedAddress.contactCountry,
				contactPostCode: this.state.selectedAddress.contactPostCode,
				contactState: this.state.selectedAddress.contactState,
				servicePackCode: this.state.packSelected.ServicePackCode,
				packSelected: this.state.packSelected,
				numberOfCredits: this.state.numberOfCredits,
				freeCredits: this.state.freeCredits,
				promoSelected: this.state.promoSelected,
				subscriptionsData: this.state.subscriptions,
				servicesData: this.state.services,
				addonData: this.state.addonSelected,
				totalOrderExclTax: this.state.totalOrderExclTax,
				totalOrderInclTax: this.state.totalOrderInclTax,
				isSubscriptionVisible: this.state.isSubscriptionVisible,
				isAddressEdited: this.state.isAddressEdited,
				dbs: this.state.dbs,
			};

			// Google Tag Manager data layer
			const tagManagerArgs = {
				dataLayer: {
					event: 'pageview',
					page: 'summary'
				}
			};

			//console.log(summary);

			TagManager.dataLayer(tagManagerArgs);

			return (
				<OrderSummary
					handleSummaryConfirm={this.handleSummaryConfirm}
					handleSummaryCancel={this.handleSummaryCancel}
					currency={this.state.currency}
					locale={this.state.locale}
					{...summary}
				/>
			);
		}
	};

	renderAccountCreation = () => {
		if (this.state.createNewAccount && !this.state.showSuccess && !this.state.showFail && !this.state.showPayment) {
			// Google Tag Manager data layer
			const tagManagerArgs = {
				dataLayer: {
					event: 'pageview',
					page: 'account_creation'
				}
			};

			TagManager.dataLayer(tagManagerArgs);

			//console.log("is address edited: ", this.state.isAddressEdited);

			return (
				<CreateAccount
					businessRef={this.state.businessRef}
					businessName={this.state.businessName}
					departmentName={this.state.departmentName}
					contactEmail={this.state.eMail}
					forename={this.state.forename}
					middleName={this.state.middleName}
					surname={this.state.surname}
					password={this.state.password}
					contactPhone={this.state.phoneNumber}
					promoCode={this.state.promoCode}
					registeredAddr1={this.state.registeredAddress.addr1}
					registeredAddr2={this.state.registeredAddress.addr2}
					registeredAddr3={this.state.registeredAddress.addr3}
					registeredCity={this.state.registeredAddress.city}
					registeredCountry={this.state.registeredAddress.country}
					registeredPostCode={this.state.registeredAddress.postCode}
					registeredState={this.state.registeredAddress.state}
					contactAddr1={this.state.selectedAddress.contactAddr1}
					contactAddr2={this.state.selectedAddress.contactAddr2}
					contactAddr3={this.state.selectedAddress.contactAddr3}
					contactCity={this.state.selectedAddress.contactCity}
					contactCountry={this.state.selectedAddress.contactCountry}
					contactPostCode={this.state.selectedAddress.contactPostCode}
					contactState={this.state.selectedAddress.contactState}
					catalogueSelected={this.state.catalogueSelected}
					catalogueName={this.state.catalogueName}
					lineOfBusinessId={this.state.packSelected.Jurisdictions[0].LinesOfBusiness[0].LineOfBusinessID}
					servicePackCode={this.state.packSelected.ServicePackCode}
					servicePackName={this.state.packSelected.ServicePackName}
					totalCredits={this.state.totalCredits}
					totalNet={this.state.totalNet}
					totalTax={this.state.totalTax}
					orderTotal={this.state.orderTotal}
					numberOfCredits={this.state.numberOfCredits}
					// subscriptionSelected={this.state.subscriptionSelected}
					subscriptions={this.state.subscriptions}
					services={this.state.services}
					addonSelected={this.state.addonSelected}
					promoSelected={this.state.promoSelected}
					handleAccountCreation={this.handleAccountCreation}
					spotliteStartDate={this.state.spotliteStartDate}
					spotliteEndDate={this.state.spotliteEndDate}
					spotliteDueDate={this.state.spotliteDueDate}
					billingFrequency={this.state.billingFrequency}
					currencyCode={this.state.currencyCode}
					isAddressEdited={this.state.isAddressEdited}
				/>
			);
		}
	}

	renderPayment = () => {
		if (this.state.showPayment && !this.state.showSuccess && !this.state.showFail) {
			// Google Tag Manager data layer
			const tagManagerArgs = {
				dataLayer: {
					event: 'pageview',
					page: 'payment'
				}
			};

			TagManager.dataLayer(tagManagerArgs);

			if (this.state.orderTotal !== 0) {
				return (
					<PaymentMethod
						invoiceNumber={this.state.invoiceNumber}
						currencyCode={this.state.currencyCode}
						orderTotal={this.state.orderTotal}
						orderDescription={this.state.orderDescription}
						selectedAddress={this.state.selectedAddress}
						error={this.state.error}
						setError={this.setError}
						handlePaymentSuccess={this.handlePaymentSuccess}
						handlePayByInvoiceButton={this.handlePayByInvoiceButton}
					/>
				)
			} else {
				// here we update the BusinessAccount and set it to live
				// and handle any referral updates --- modify handlePaymentSuccess() function
				this.handlePaymentSuccess();
			}
		}
		return null;
	};

	renderRegistrationSuccess() {
		const {
			showSuccess,
			businessRef,
			businessName,
			surname,
			forename,
			eMail,
			phoneNumber,
			departmentName,
			registeredAddress,
			payByInvoice
		} = this.state;

		if (showSuccess) {

			// Google Tag Manager data layer
			const tagManagerArgs = {
				dataLayer: {
					event: 'pageview',
					page: 'registration_success'
				}
			};

			TagManager.dataLayer(tagManagerArgs);

			return (
				<RegistrationSuccess
					businessRef={businessRef}
					businessName={businessName}
					surname={surname}
					forename={forename}
					eMail={eMail}
					phoneNumber={phoneNumber}
					departmentName={departmentName}
					registeredAddress={registeredAddress}
					payByInvoice={payByInvoice}
					closeBrowserTab={this.closeBrowserTab}
				/>
			)
		}
	}

	renderRegistrationFail() {
		const {
			showFail,
			businessRef,
			businessName,
			surname,
			forename,
			eMail,
			phoneNumber,
			error
		} = this.state;

		if (showFail) {

			// Google Tag Manager data layer
			const tagManagerArgs = {
				dataLayer: {
					event: 'pageview',
					page: 'registration_fail'
				}
			};

			TagManager.dataLayer(tagManagerArgs);

			return (
				<RegistrationFail
					businessRef={businessRef}
					businessName={businessName}
					surname={surname}
					forename={forename}
					eMail={eMail}
					phoneNumber={phoneNumber}
					error={error}
					closeBrowserTab={this.closeBrowserTab}
				/>
			)
		}
	}

	closeBrowserTab = () => {
		// close browser tab
		// window.open(window.location, '_self').close();   
		// return false;  
		this.setState({
			closeWindow: true
		});
	}

	render() {
		const isRenderingServicePacks = this.state.pinGenerated && this.state.pinVerified && this.state.businessRef;
		const wrapperClassNames = isRenderingServicePacks ? 'wrapper wrapper--center wrapper--wide' : 'wrapper wrapper--single';

		return (
			<div>
				<Header />

				<main className="container group" role="main">
					<div className={wrapperClassNames}>

						{this.renderWelcomePage()}

						{this.renderBusinessSearch()}

						{this.renderRegistrationForm()}

						{this.renderVerificationCode()}

						{this.renderPinNotReceived()}

						{ /* this.renderVerifyEmail() */}

						{this.renderServicePacks()}

						{this.renderSummary()}

						{this.renderAccountCreation()}

						{this.renderPayment()}

						{this.renderRegistrationSuccess()}

						{this.renderRegistrationFail()}

					</div>
				</main>

				<Footer className="container group" role="contentinfo" />
			</div>
		);
	}
}

export default App;